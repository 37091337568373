import React from 'react';
import { Container, Row, Col, Table, Spinner } from 'react-bootstrap';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Layout from '../components/layout';

class listTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadding: true
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loadding: false });
    }, 8000);
  }

  render() {
    dayjs.extend(relativeTime);
    const { loadding } = this.state;
    return (
      <Layout>
        <Container fluid className="pt-5">
          <Row>
            <Col>
              <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Username</th>
                  </tr>
                </thead>
                {loadding ? (
                  <tbody>
                    <tr>
                      <td colSpan={4}>
                        <Spinner animation="border" variant="light" size="sm" />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>@fat</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td colSpan="2">Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default listTest;
